/* Website Colors */
.color {
    text-align: center;
    padding: 10px;
    color: white;
    margin-bottom: 10px;
    margin-top: 10px;
}

/*Background colors*/
.primaryGreen {
    background-color: theme-color('success');
}

.primaryRed {
    background-color: map_get($colors, 'red');
}

.positiveBlue {
    background-color: theme-color('info');
}

.positiveCyan {
    background-color: theme-color('info');
}

.cautionOrange {
    background-color: theme-color('warning');
}

.routiqueGreen {
    background-color: color('green');
}

.routeiqueEggplant {
    background-color: color('eggplant');
}

.black-background {
    background-color: $black;
}

.darkGrey {
    background-color: color('gray-dark');
}

.lightGrey {
    background-color: color('gray-medium');
}

.lighterGrey {
    background-color: color('gray-light');
    color: #000000;
}

.alertNegative {
    background-color: theme-color('danger');
}

.alertPositive {
    background-color: theme-color('success');
}

.alertCaution {
    background-color: theme-color('warning');
}

.white {
    background-color: color('white');
}
