/* containers */
.container-border {
    border: 1px solid theme-color('dark');
    padding: 15px 20px;
}

.center-row {
    margin-left: 15%;
    margin-right: 15%;
}

.col-select-hub {
    text-align: center;
    padding: 2%;

    p {
        margin-top: 5%;
    }
}

/* margins and padding */

.ml-25 {
    margin-left: 25px;
}

.ml-50 {
    margin-left: 50px;
}

.mr-25 {
    margin-right: 25px;
}

.mr-50 {
    margin-right: 50px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-100 {
    margin-top: 100px;
}

/* font sizes */

.fs-14 {
    font-size: 14px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-20 {
    font-size: 20px !important;
}

/* Icons */

.expand-arrow {
    font-size: 20px;
    cursor: pointer;
}

/* Headers */

.page-header {
    font-size: 18px;
    border-bottom: 1px solid theme-color('light');
    margin-bottom: 20px;
    margin-top: 50px;
    color: theme-color('dark');

    .back-arrow {
        color: theme-color('dark');
        margin-right: 1rem;

        &:hover {
            color: theme-color('dark');
        }
    }

    span.right-text-link {
        cursor: pointer;
        i {
            color: theme-color('warning');
            margin-left: 1em;
        }
    }

    p {
        margin-bottom: 1em;
    }
}

.sub-header {
    border-bottom: 1px solid #f2efef;
    padding-bottom: 10px;
    width: 50%;
}

/* Navigation */
.nav-tabs {
    border-bottom: 1px solid theme-color('warning');

    a {
        color: theme-color('dark');
    }
}

// TODO Update this to be a bit more sassy in it's implementation.
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    border-radius: 0;
    border-bottom: 1px solid white;
    color: theme-color('dark');
    font-weight: $font-weight-bolder;
    background-color: #fff;
    border-color: theme-color('warning') theme-color('warning') #fff;
}

.options-list {
    margin-top: 60px;
}

/* Alerts */

//The alert box is where all of the alert types will appear (success, wanring, info). These alerts are rendered
//in the box on the master template blade when a view is returned with errors, success or info or if a route is redirected back with
//errors, etc.
//The box alerts will always appear in a static position in the top right corner of the screen.
//They will not push or affect any other content on the page
//The width is responsive with screen width
.alert-box {
    position: absolute;
    top: 3rem;
    right: 1.5%;
    z-index: 100;
    width: 473px;
    max-width: 473px;

    .alert {
        width: 100%;
    }
}

.alert {
    z-index: 10;
    font-weight: $font-weight-bold;
    //float: right;
    background-color: white;
    border: 1px solid;
    border-radius: 0%;

    -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);

    margin: 5px 10px 5px;

    i {
        font-size: 22px;
        position: relative;
        left: -15px;
        top: -7px;
        float: left;
    }

    span {
        display: block;
        overflow: hidden;
        font-size: 14px;
    }
}

.alert-success {
    color: theme-color('success');
    border-color: theme-color('success');
}

.alert-danger {
    color: theme-color('danger');
    border-color: theme-color('danger');
}

.alert-warning {
    color: theme-color('warning');
    border-color: theme-color('warning');
}

.alert-info {
    color: theme-color('warning');
    border-color: theme-color('warning');
}

.alert-dismissible .close {
    &:focus {
        outline: none;
    }

    position: absolute;
    top: -10px;
    right: -15px;

    span {
        font-size: 18px;
    }

    color: inherit;
}

/*lists*/
.list-shadow {
    -webkit-box-shadow: -4px 4px 5px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: -4px 4px 5px 0px rgba(0, 0, 0, 0.2);
    box-shadow: -4px 4px 5px 0px rgba(0, 0, 0, 0.2);
}

/* Breadcrumbs */
.breadcrumb {
    display: block;
    background-color: transparent;
    margin-bottom: 0%;
    position: absolute;
    left: 20%;
    right: 20%;
    padding-top: 0%;

    .breadcrumb-wrapper {
        display: block;
        margin: 0 auto;
        width: fit-content;

        .bc-status {
            display: inline-block;
            text-align: center;
            font-size: 12px;
            font-weight: 600;
            padding: 10px 10px 10px 10px;

            .bc-square {
                vertical-align: top;
                height: 14px;
                min-width: 70px;
                border: 1px solid theme-color('gray-medium');
                background-color: transparent;
            }

            .bc-success {
                background-color: theme-color('success');
            }

            .bc-warning {
                background-color: theme-color('warning');
            }

            .bc-info {
                background-color: theme-color('info');
            }

            .bc-info {
                background-color: theme-color('info');
            }

            .bc-danger {
                background-color: theme-color('danger');
            }
        }
    }
}

/* Order Receipt */

.container-receipt {
    max-width: 310px;
    max-height: 100%;
    margin-top: 10%;
    margin-bottom: 10%;
    border: $border-width solid theme-color('dark');
    position: relative;

    p {
        margin: 0px;
        padding: 0px;
    }

    .receipt-header {
        width: 100%;
        height: 45px;
        text-transform: uppercase;
        padding: 5%;
    }

    .receipt-divider,
    .receipt-status {
        border-top: $border-width solid theme-color('dark');
        border-bottom: $border-width solid theme-color('dark');
        width: 100%;
        -webkit-box-shadow: 0px 4px 4px 0px theme-color('light'); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
        -moz-box-shadow: 0px 4px 4px 0px theme-color('light'); /* Firefox 3.5 - 3.6 */
        box-shadow: 0px 4px 4px 0px theme-color('light');
    }

    .receipt-status {
        //max-height: $maxInputHeight;
        //height: $maxInputHeight;
        //line-height: $maxInputHeight;
        //padding: 5%;
        h4 {
            display: inline-block;
            vertical-align: middle;
        }
    }

    .receipt-divider {
        height: 14px;
        max-height: 14px;
    }

    .po-status3,
    .po-status5 {
        background-color: theme-color('success');
        color: white;
    }

    .po-status1,
    .po-status4 {
        background-color: theme-color('warning');
        color: white;
    }

    .po-status2 {
        background-color: theme-color('danger');
        color: white;
    }

    .po-status6 {
        background-color: theme-color('info');
        color: white;
    }

    .receipt-body {
        width: 100%;
        overflow-x: scroll;
        padding: 1rem;
        min-height: 350px;
        max-height: 500px;

        ol {
            width: 100%;
            counter-reset: receipt-counter;
            list-style: none;
            padding: 0px;
            margin-top: 1rem;
            margin-bottom: 1rem;

            li {
                counter-increment: receipt-counter;
                display: flex;
                padding-left: 0px;
                padding-bottom: 1.5rem;
                padding-top: 0.5rem;
                border-top: $border-width solid theme-color('dark');
                width: 100%;

                &:before {
                    content: counter(receipt-counter);
                    font-size: 30px;
                    font-weight: $font-weight-bold;
                    padding-right: 1rem;
                    position: relative;
                    top: -0.5rem;
                }

                div {
                    width: inherit;
                }
            }
        }
    }

    .receipt-footer {
        width: 100%;
        padding: 1rem;
        text-align: center;

        hr {
            width: 80%;
        }

        button {
            margin-top: 5%;
            margin-bottom: 3%;
            display: block;
        }

        table {
            td {
                padding-right: 10px;
            }

            tr.divider {
                border-top: $border-width solid theme-color('dark');
                empty-cells: show;
                border-collapse: collapse;
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }
    }
}

.receiveInfo {
    margin-bottom: 3rem;

    table {
        width: 100%;

        td {
            padding: 5px;

            &:last-of-type {
                width: 6rem;
            }
        }

        tr.divider {
            border-top: $border-width solid theme-color('dark');
            border-color: theme-color('light');
            empty-cells: show;
            border-collapse: collapse;
            padding-top: 10px;
            padding-bottom: 10px;
            width: 100%;
        }
    }
}

/* Mapbox styles */
#map {
    top: 0;
    bottom: 0;
    width: 100%;
    height: 500px;
}

.coordinates {
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    position: absolute;
    bottom: 10px;
    left: 10px;
    padding: 5px 10px;
    margin: 0;
    font-size: 11px;
    line-height: 18px;
    border-radius: 3px;
    display: none;
}
