.panel-container {
    border: 1px solid lightgray;
    padding: 5%;
    max-height: 800px;
    overflow: auto;
}

.panel-block-title {
    text-transform: uppercase;
}

.panel-positive-blue {
    box-shadow: none;

    .panel-heading {
        padding: 10px 30px;
        background-color: theme-color('info');
        color: white;

        .padding-vertical-sm {
            padding-top: 9px;
            padding-bottom: 9px;
        }

        .edit-btn {
            background: transparent;
            position: absolute;
            border: none;
            float: right;
            right: 40px;
            cursor: pointer;
            z-index: 10;

            &.shift-right {
                right: 15px;
            }

            .fa-edit {
                color: white;
                font-size: 14pt;
            }
        }

        .row {
            padding: 0;
        }
    }

    .panel-body {
        border: 2px solid theme-color('light');
        border-top: none;
        padding: 10px 30px;

        .body-icon {
            position: absolute;
            left: -2px;
            top: 3px;
            color: theme-color('info');
        }
    }
}

.attachment-block {
    margin-bottom: 0;
    margin-top: -3px;

    .panel-heading {
        height: 10px;
        padding: 0;
        border-radius: 0;
    }
}

.panel-attached-block,
.attachment-block {
    .edit-btn {
        outline: none;
        padding-top: 15px;

        .fa-edit {
            color: theme-color('info') !important;
        }
    }
}

.panel-attached-block {
    .panel-group {
        margin-bottom: 0;
    }

    .panel {
        border-radius: 4px 4px 0 0;
    }

    .edit-btn {
        padding-top: 35px;
    }

    .panel-positive-blue {
        &.attachment-block {
            border-radius: 0;
            margin-top: -2px;

            .panel-heading {
                padding: 0;
                height: 10px;
                border-radius: 0;
            }
        }
    }
}

.panel-title-flex {
    display: flex;
}

.panel-title-btn {
    &.btn-add {
        padding: 5px 10px;
        font-size: 12px;
        float: right;
    }
}

.pick-card-wrapper {
    z-index: initial !important;
}

.pick-card {
    border-color: theme-color('dark');

    .card-header {
        color: white;
    }

    .pick-product {
        border: 1px;
        border-color: theme-color('dark');
        border-style: solid none;
    }

    .card-body {
        max-height: 500px;
        overflow: auto;

        ul.pick-item {
            margin: 0;
            list-style: none;
            padding: 0;

            li {
                border-bottom: 1px solid theme-color('dark');
                padding: 5px;
                list-style: none;
                width: 100%;
                text-align: center;
                cursor: pointer;

                &:hover {
                    background-color: rgba(157, 161, 155, 0.27);
                }
            }

            li.selected {
                background-color: theme-color('dark');
                color: white;
            }
        }
    }
}
