// Variables
//
// These variables will override anything set in the default Bootstrap library.
// We can also set our own variables for our own use.
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// TODO Fix / move.
$hub_menu_width: 491px;
$hub_menu_height: 236px;
$circle_width: 236px;
$hub_body_height: 170px;

$gray-dark: #282827;
$gray-medium: #d0d0d0;
$gray-light: #f4f4f4;
$white: #ffffff;

$grays: (
    'gray-light': $gray-light,
    'gray-medium': $gray-medium,
    'gray-dark': $gray-dark
);

$logo-green: #76bc21;
$logo-orange: #ff6a41;
$green: #9bdb35;
$success: $green;
$warning: #ffa640;
$red: #fe0505;
$eggplant: #3b4559;
$cyan: #71d8c3;
$blue: #4fbcff;

$colors: (
    'cyan': $cyan,
    'blue': $blue,
    'eggplant': $eggplant,
    'green': $green,
    'logo-green': $logo-green,
    'logo-orange': $logo-orange,
    'orange': $warning,
    'red': $red,
    'success': $success
);

$primary: $green;
$secondary: $logo-orange;
$success: $success;
$info: $blue;
$warning: $warning;
$danger: $red;

$theme-colors: (
    // Because we're still using Bootforms which provides Bootstrap 3 markup
    // we add a default theme in to account for the buttons spat out from there.
    'default': $green,
    'primary': $green,
    'secondary': $logo-orange,
    'success': $success,
    'info': $blue,
    'warning': $warning,
    'danger': $red
);

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 185;

// Customize the light and dark text colors for use in our YIQ color contrast function.
//$yiq-text-dark:             $gray-900 !default;
//$yiq-text-light:            $white !default;

// Links
//
// Style anchor elements.

$link-color: $blue;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-shadows: true;

// Row colour, when selected (tr.selected)
$table-row-selected: $gray-light;

// Text colour of the interaction control elements (info, filter, paging etc)
$table-control-color: $gray-dark;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-xs: 1.5;

$border-radius: 0.25rem;
$border-radius-lg: 0.3rem;
$border-radius-sm: 0.2rem;
$border-radius-xs: 0.15rem;

$component-active-bg: $blue;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: 'Lato', 'Open Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';

$font-size-base: 1rem;
$font-size-xs: $font-size-base * 0.7;

$font-weight-lighter: lighter;
$font-weight-light: 200;
$font-weight-normal: 300;
$font-weight-bold: 400;
$font-weight-bolder: 600;

$h1-font-size: $font-size-base * 2.5; // 1.875rem -> 30px
$h2-font-size: $font-size-base * 2; // 1.5rem -> 24px
$h3-font-size: $font-size-base * 1.75; // 1.3125rem -> 21px
$h4-font-size: $font-size-base * 1.5; // 1.125rem -> 18px
$h5-font-size: $font-size-base * 1.25; // 0.9375rem -> 15px
$h6-font-size: $font-size-base; // 0.75rem -> 12px

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

//$table-cell-padding:          .75rem !default;
//$table-cell-padding-sm:       .3rem !default;

//$table-color:                 $body-color !default;
//$table-bg:                    null !default;
//$table-accent-bg:             rgba($black, .05) !default;
//$table-hover-color:           $table-color !default;
//$table-hover-bg:              rgba($black, .075) !default;
//$table-active-bg:             $table-hover-bg !default;

//$table-border-width:          $border-width !default;
//$table-border-color:          $border-color !default;

//$table-head-bg:               $gray-200 !default;
//$table-head-color:            $gray-700 !default;

$table-dark-color: $white;
$table-dark-bg: $eggplant;
//$table-dark-accent-bg:        rgba($white, .05) !default;
//$table-dark-hover-color:      $table-dark-color !default;
//$table-dark-hover-bg:         rgba($white, .075) !default;
$table-dark-border-color: $white;

//$table-striped-order:         odd !default;

//$table-caption-color:         $text-muted !default;

//$table-bg-level:              -9 !default;
//$table-border-level:          -6 !default;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y-xs: 0.2rem;
$input-btn-padding-x-xs: 0.4rem;
$input-btn-font-size-xs: $font-size-xs;
$input-btn-line-height-xs: $line-height-xs;

$input-btn-padding-y-sm: 0.25rem;
$input-btn-padding-x-sm: 0.5rem;
//$input-btn-font-size-sm:      $font-size-sm;
//$input-btn-line-height-sm:    $line-height-sm;

$input-btn-padding-y-lg: 0.5rem;
$input-btn-padding-x-lg: 1rem;
//$input-btn-font-size-lg:      $font-size-lg;
//$input-btn-line-height-lg:    $line-height-lg;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y-xs: $input-btn-padding-y-xs;
$btn-padding-x-xs: $input-btn-padding-x-xs;
$btn-font-size-xs: $input-btn-font-size-xs;
$btn-line-height-xs: $input-btn-line-height-xs;

$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
//$btn-font-size-sm:            $input-btn-font-size-sm;
//$btn-line-height-sm:          $input-btn-line-height-sm;

$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
//$btn-font-size-lg:            $input-btn-font-size-lg;
//$btn-line-height-lg:          $input-btn-line-height-lg;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius;
$btn-border-radius-lg: $border-radius-lg;
$btn-border-radius-sm: $border-radius-sm;
$btn-border-radius-xs: $border-radius-xs;

$btn-box-shadow: 1px 1px 2px 0 #ccc;
//box-shadow: 1px 1px 2px 0 #ccc;
$btn-focus-width: 0;
$btn-focus-box-shadow: none;
//$btn-disabled-opacity:        .65 !default;
$btn-active-box-shadow: none;

// Forms

$input-border-color: $gray-medium;

// Modals

// Padding applied to the modal body
//$modal-inner-padding:               1rem !default;
//
//$modal-dialog-margin:               .5rem !default;
//$modal-dialog-margin-y-sm-up:       1.75rem !default;
//
//$modal-title-line-height:           $line-height-base !default;
//
//$modal-content-color:               null !default;
//$modal-content-bg:                  $white !default;
//$modal-content-border-color:        rgba($black, .2) !default;
//$modal-content-border-width:        $border-width !default;
//$modal-content-border-radius:       $border-radius-lg !default;
//$modal-content-box-shadow-xs:       0 .25rem .5rem rgba($black, .5) !default;
//$modal-content-box-shadow-sm-up:    0 .5rem 1rem rgba($black, .5) !default;
//
//$modal-backdrop-bg:                 $black !default;
//$modal-backdrop-opacity:            .5 !default;
//$modal-header-border-color:         $border-color !default;
//$modal-footer-border-color:         $modal-header-border-color !default;
//$modal-header-border-width:         $modal-content-border-width !default;
//$modal-footer-border-width:         $modal-header-border-width !default;
//$modal-header-padding-y:            1rem !default;
//$modal-header-padding-x:            1rem !default;
//$modal-header-padding:              $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 500px !default;
$modal-sm: 300px !default;

//$modal-fade-transform:              translate(0, -50px) !default;
//$modal-show-transform:              none !default;
//$modal-transition:                  transform .3s ease-out !default;

// Toasts

$toast-min-width: 150px;
$toast-margin-from-corner: 5px;

//$toast-max-width:                   350px !default;
//$toast-padding-x:                   .75rem !default;
//$toast-padding-y:                   .25rem !default;
//$toast-font-size:                   .875rem !default;
//$toast-color:                       null !default;
$toast-background-color: $white;
//$toast-border-width:                1px !default;
//$toast-border-color:                rgba(0, 0, 0, .1) !default;
//$toast-border-radius:               .25rem !default;
//$toast-box-shadow:                  0 .25rem .75rem rgba($black, .1) !default;
//
//$toast-header-color:                $gray-600 !default;
//$toast-header-background-color:     rgba($white, .85) !default;
//$toast-header-border-color:         rgba(0, 0, 0, .05) !default;

// Font Awesome
$fa-font-path: '../fontawesome/webfonts';
