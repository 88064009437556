.product-list {
    float: left;
    list-style: none;
    padding: 0;
    position: relative;
    z-index: 10000;
    max-height: 200px;
    width: inherit;

    li {
        padding: 10px;
        background: white;
        border-bottom: 1px solid gray('gray-light');

        &:hover {
            cursor: pointer;
            background: gray('gray-medium');
            color: black;
        }
    }
}

li.psedo-hover {
    background: gray('gray-medium');
    color: black;
}

.typeaheadContainer {
    position: absolute;
    float: left;
    max-height: 300px;
    width: 100%;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 10000;
}
