.toast-container {
    position: sticky;
    z-index: 1055;
    top: 0;
}

.toast-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    margin: $toast-margin-from-corner;
}

.toast {
    min-width: $toast-min-width;
}
