// jQuery UI stylesheet imports this one - there are just two places where we
// don't want DataTabels default styles assigned for jQuery UI, so rather than
// duplicating the whole file, this is the best option
$jqueryui: false !default;

table.table {
    caption {
        caption-side: top;
    }
    thead {
        th {
            border-right: solid 1px;
            border-left: solid 1px;
            color: white !important;
        }
    }

    tbody,
    tfoot {
        td {
            vertical-align: middle !important;
        }
        .tc-icon {
            font-size: 25px;
            text-align: center;

            i {
                border: 1px solid;
                padding: 5px 8px;
                margin-left: 5px;
                margin-right: 5px;
                cursor: pointer;
            }

            .no-border {
                border: none;
            }

            .no-pointer {
                cursor: none;
            }
        }
    }
}

.page-item {
    &:first-child {
        .page-link {
            margin-right: 2px;
        }
    }

    &:not(:first-child):not(:last-child) {
        .page-link {
            margin-right: 2px;
        }
    }

    &:last-child {
        .page-link {
            margin-right: 0;
        }
    }
}

.dataTables_info + .dataTables_paginate {
    float: right;
}

/* ******************** INFO TABLE ONLY **************************** */
table.infoTable,
table.table table.infoTable {
    thead {
        th {
            background-color: theme-color('warning') !important;
            color: theme-color('light');
        }
    }

    tbody {
        background-color: theme-color('light');
    }
}

//
//
///* ******************** DATA TABLE ONLY **************************** */
//
.dataTables_wrapper {
    div.dataTables_processing {
        z-index: 105 !important;
    }

    margin-bottom: 2%;

    table.dataTable {
        //
        //  /*
        //   * Header and footer styles
        //   */
        thead {
            /* This fix is for the sorting icons, which do not behave the same with bootstrap for css and js */
            .sorting,
            .sorting_asc,
            .sorting_desc {
                &::before {
                    content: none;
                }

                &::after {
                    color: theme-color('warning');
                    font-family: 'Glyphicons Halflings';
                    float: right;
                    opacity: 1;
                }
            }

            .sorting::after {
                content: '\e150' !important;
            }

            .sorting_asc::after {
                content: '\e155' !important;
            }

            .sorting_desc::after {
                content: '\e156' !important;
            }
        }
    }
}
