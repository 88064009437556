.btn {
    // Add our margins and helper styling for any icon that's added to a button.
    span,
    i {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 0.5rem;
    }
}

@each $color, $value in $theme-colors {
    .btn-link-#{$color} {
        @include button-link-variant($value);
    }
}

// We add an additional size option.
.btn-xs {
    @include button-size(
        $btn-padding-y-xs,
        $btn-padding-x-xs,
        $btn-font-size-xs,
        $btn-line-height-xs,
        $btn-border-radius-xs
    );
}

// TODO below classes need to be switch over to new styles in there implementation. Once implementations changed we can
//  remove the code below

.btn-submit {
    background-color: theme-color('success');
    color: white;

    &:hover {
        background-color: color-yiq(darken(theme-color('success'), 7.5%));
    }
}

.btn-reset {
    @include button-variant(theme-color('warning'), theme-color('warning'));

    background-color: color('white');
    color: theme-color('warning');
    border-color: color('white');

    text-decoration: underline;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    &:hover {
        background-color: color('white');
        color: theme-color('warning');
        border-color: color('white');
        text-decoration: none;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle,
    &:focus,
    &:active {
        -webkit-box-shadow: none; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
        -moz-box-shadow: none; /* Firefox 3.5 - 3.6 */
        box-shadow: none;
        background-color: color('white');
        color: theme-color('warning');
        border-color: color('white');
        text-decoration: none;
    }
}

.btn-bottom {
    position: absolute;
    bottom: 0;
}

.intercom-selectable {
    /*The styles here align with the positioning of the intercom-launcher-frame class*/
    position: fixed;
    bottom: 20px;
    right: 20px;
    height: 60px;
    width: 60px;
}
