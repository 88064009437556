.modal-full {
    max-width: 90%;
}

.modal {
    .error {
        color: theme-color('danger');
    }
    .info {
        color: theme-color('warning');
    }
    .success {
        color: theme-color('success');
    }
}

//TODO: Change this to work for the financial hub modals
.fh-error-modal {
    width: 100% !important;

    table {
        text-align: left;

        td,
        th {
            padding: 10px;
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}
