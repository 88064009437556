.form-group {
    clear: both;

    textarea {
        &.form-control {
            max-height: none;
            height: inherit !important;
        }
    }
}

.input-static {
    .help-block {
        position: absolute;
        top: 65px;
    }
}

.group-inline {
    align-items: center;
    .btn {
        bottom: 0;
    }
}

label {
    font-weight: 700;
    font-size: 12px;
}

// Re add in some margin to our checkboxes, radio buttons and toggles.
.checkbox,
.checkbox-inline,
.radio,
.radio-inline {
    input {
        margin-right: 0.25rem;
    }

    label .toggle,
    .toggle {
        // We need to explicitly remove margin left from the styles provided in the vendor files
        margin-left: 0;
        margin-right: 0.25rem;
    }
}

.checkbox-inline + .checkbox-inline,
.radio-inline + .radio-inline {
    input {
        margin-left: 0.5rem;
    }
    label .toggle,
    .toggle {
        margin-left: 0.5rem;
    }
}

.required label:after {
    color: theme-color('danger');
    content: ' *';
    display: inline;
    font-weight: 600;
    font-size: 12px;
}

.row-stack > .form-column > p,
.row-stack > .form-column > i {
    display: inline-block;
}

.row-stack > .form-column > .btn-submit {
    margin-right: 15px;
}

.row-stack > .col {
    padding-left: 0 !important;
}

.fa-long-arrow-right {
    color: theme-color('info');
    font-size: 25px;
    vertical-align: middle;
}

textarea.ta-full {
    width: 100%;
    border: 1px solid gray('gray-medium');
}

.error-input {
    border: 2px solid #f2b8d3;
    box-shadow: 0 0 10px #db678b;
}

.hide-number-spinner::-webkit-inner-spin-button,
.hide-number-spinner::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.hide-number-spinner {
    -moz-appearance: textfield; /* Firefox */
}

.has-error .help-block {
    color: theme-color('danger');
}
