@mixin button-outline-variant(
    $color,
    $color-hover: darken($color, 7.5%),
    $active-background: $color,
    $active-border: darken($color, 7.5%)
) {
    color: $color;
    border-color: $color;

    @include hover {
        color: $color-hover;
        //background-color: $active-background;
        border-color: $active-border;
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 $btn-focus-width rgba($color, 0.5);
    }

    &.disabled,
    &:disabled {
        color: $color;
        background-color: transparent;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: $color-hover;
        //background-color: $active-background;
        border-color: $active-border;
        transform: translate(1px, 1px);

        &:focus {
            // Avoid using mixin so we can pass custom focus shadow properly
            @if $enable-shadows and $btn-active-box-shadow != none {
                box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color, 0.5);
            } @else {
                box-shadow: 0 0 0 $btn-focus-width rgba($color, 0.5);
            }
        }
    }
}

@mixin button-link-variant($color, $color-hover: darken($color, 7.5%)) {
    // Make a button look and behave like a link
    font-weight: $font-weight-normal;
    color: $color;
    text-decoration: $link-decoration;

    @include hover {
        color: $color-hover;
        text-decoration: $link-hover-decoration;
    }

    &:focus,
    &.focus {
        text-decoration: $link-hover-decoration;
        box-shadow: none;
    }

    &:disabled,
    &.disabled {
        color: $btn-link-disabled-color;
        pointer-events: none;
    }

    // No need for an active state here
}
